import { current, createSlice } from "@reduxjs/toolkit";

const initialState = {
  title: "",
  firstname: "",
  lastname: "",
  contact_gender: "",
  eu_shoe_size: "",
  date_of_birth: { day: "", month: "", year: "" },
  date_of_birth_day: "",
  date_of_birth_month: "",
  date_of_birth_year: "",
  nationality: "",
  relationship_status: "",
  street: "",
  city: "",
  state: "",
  country: "",
  zip: "",
  headshot_photo_upload:"",
  pb_same: false,
  pb_street: "",
  pb_city: "",
  pb_state: "",
  pb_country: "",
  pb_zip: "",
  email: "",
  telephone: "",
  mobile: "",
  spouse_title: "",
  spouse_first_name: "",
  spouse_last_name: "",
  spouse_date_of_birth: { day: "", month: "", year: "" },
  spouse_gender: "",
  spouse_eu_shoe_size: "",
  spouse_date_of_birth_day: "",
  spouse_date_of_birth_month: "",
  spouse_date_of_birth_year: "",
  spouse_email: "",
  spouse_telephone: "",
  spouse_headshot: "",
  spouse_mobile: "",
  child_0_title: "",
  child_0_name: "",
  child_0_charging_privileges: false,
  child_0_date_of_birth: { day: "", month: "", year: "" },
  child_0_date_of_birth_day: "",
  child_0_date_of_birth_month: "",
  child_0_date_of_birth_year: "",
  child_0_email: "",
  child_0_mobile: "",
  child_0_gender: "",
  child_0_headshot: "",
  child_1_title: "",
  child_1_name: "",
  child_1_charging_privileges: false,
  child_1_date_of_birth: { day: "", month: "", year: "" },
  child_1_date_of_birth_day: "",
  child_1_date_of_birth_month: "",
  child_1_date_of_birth_year: "",
  child_1_email: "",
  child_1_mobile: "",
  child_1_gender: "",
  child_1_headshot: "",
  child_2_title: "",
  child_2_name: "",
  child_2_charging_privileges: false,
  child_2_date_of_birth: { day: "", month: "", year: "" },
  child_2_date_of_birth_day: "",
  child_2_date_of_birth_month: "",
  child_2_date_of_birth_year: "",
  child_2_email: "",
  child_2_mobile: "",
  child_2_gender: "",
  child_2_headshot: "",
  child_3_title: "",
  child_3_name: "",
  child_3_charging_privileges: false,
  child_3_date_of_birth: { day: "", month: "", year: "" },
  child_3_date_of_birth_day: "",
  child_3_date_of_birth_month: "",
  child_3_date_of_birth_year: "",
  child_3_email: "",
  child_3_mobile: "",
  child_3_gender: "",
  child_3_headshot: "",
  child_4_title: "",
  child_4_name: "",
  child_4_charging_privileges: false,
  child_4_date_of_birth: { day: "", month: "", year: "" },
  child_4_date_of_birth_day: "",
  child_4_date_of_birth_month: "",
  child_4_date_of_birth_year: "",
  child_4_email: "",
  child_4_mobile: "",
  child_4_gender: "",
  child_4_headshot: "",
  child_5_title: "",
  child_5_name: "",
  child_5_charging_privileges: false,
  child_5_date_of_birth: { day: "", month: "", year: "" },
  child_5_date_of_birth_day: "",
  child_5_date_of_birth_month: "",
  child_5_date_of_birth_year: "",
  child_5_email: "",
  child_5_mobile: "",
  child_5_gender: "",
  child_5_headshot: "",
  child_6_title: "",
  child_6_name: "",
  child_6_charging_privileges: false,
  child_6_date_of_birth: { day: "", month: "", year: "" },
  child_6_date_of_birth_day: "",
  child_6_date_of_birth_month: "",
  child_6_date_of_birth_year: "",
  child_6_email: "",
  child_6_mobile: "",
  child_6_gender: "",
  child_6_headshot: "",
  child_7_title: "",
  child_7_name: "",
  child_7_charging_privileges: false,
  child_7_date_of_birth: { day: "", month: "", year: "" },
  child_7_date_of_birth_day: "",
  child_7_date_of_birth_month: "",
  child_7_date_of_birth_year: "",
  child_7_email: "",
  child_7_mobile: "",
  child_7_gender: "",
  child_7_headshot: "",
  occupation: "",
  industry: "",
  company: "",
  linked_in: "",
  other_clubs: "",
  clubs: "",
  sponsorship_type: "",
  proposer_name: "",
  proposer_email: "",
  proposer_how_long: "",
  sponsor_1_full_name: "",
  sponsor_1_email: "",
  sponsor_1_how_long: "",
  letter_support_1: "",
  sponsor_2_full_name: "",
  sponsor_2_email: "",
  sponsor_2_how_long: "",
  letter_support_2: "",
  interests: [],
  other_interest: "",
  reside_in_palmbeach: ""
};

export const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    saveDOBValue: (state, action) => {
      const { name, element, value } = action.payload;
      console.log(name, element, value);
      state[name][element] = value;
      return;
    },
    saveForm: (state, action) => {
      const submission = action.payload;
      const currentState = current(state);
      return { ...currentState, ...submission };
    },
    setFilename: (state, action) => {
      const { name, url } = action.payload;
      state[name] = url;
      return;
    },
    clearFilename: (state, action) => {
      const { name } = action.payload;
      state[name] = "";
      return;
    },
    removeChildIndex: (state, action) => {
      const index = action.payload;
      state[`child_${index}_date_of_birth`] = { day: "", month: "", year: "" };
      state[`child_${index}_date_of_birth_day`] = "";
      state[`child_${index}_date_of_birth_month`] = "";
      state[`child_${index}_date_of_birth_year`] = "";
      state[`child_${index}_name`] = "";
    },
  },
});

// Action creators are generated for each case reducer function
export const { saveForm, setFilename, clearFilename, saveDOBValue, removeChildIndex } =
  formSlice.actions;

export default formSlice.reducer;
