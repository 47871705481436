// import { createStore as reduxCreateStore, applyMiddleware } from "redux"
import logger from "redux-logger";

import { configureStore, combineReducers } from "@reduxjs/toolkit";
import globalReducer from "./globalSlice";
import formReducer from "./formSlice";

import storage from 'redux-persist/lib/storage'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

// const createStore = () => reduxCreateStore(rootReducer, applyMiddleware(logger));
// const createStore = () => reduxCreateStore(rootReducer);

const persistConfig = {
  key: 'root',
  storage
};

const rootReducer = combineReducers({
  global: globalReducer,
  form: formReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }).concat(process.env.NODE_ENV !== 'production' ? logger : []),
  devTools: process.env.NODE_ENV !== 'production',
})


export default store;
