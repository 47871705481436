import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  stage: 0,
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setStage: (state, action) => {
      state.stage = action.payload;
      return;
    },
    goBack: (state, action) => {
      state.stage -= 1;
      return;
    },
    goForward: (state, action) => {
      state.stage += 1;
      return;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setStage, goBack, goForward } = globalSlice.actions;

export default globalSlice.reducer;
